import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import Layout from "../components/layout"
import Seo from "../components/seo"
import Booking from '../components/dashboard/class'
import { getMethod, postMethod, putMethod } from "../helper/api"
import { Link } from 'gatsby';
import { isBrowser } from '../helper/globals';

const MyClass = ({userDetails}) => {


    const [showPending, setShowPending] = useState(!isBrowser?'pending':window?.location.hash.length ===0 || window?.location.hash==='pending');
    const [bookings, setBookings] = useState([]);
    const [bookingsR, setBookingsR] = useState([]);

    useEffect(()=>{

        async function getBookings(){
            let sub2 = await getMethod('/bookings', '', { 'payment': 1, 'isCancelled_ne': true, 'end_time_gte': new Date().toISOString().slice(0, 19).replace("T", " "), "_sort":'start_time' });
            setBookings(sub2.data);
            sub2 = await getMethod('/bookings', '', { 'payment': 1,  "_sort":'start_time:desc' });
	        setBookingsR(sub2.data);
        }

        getBookings();
    },[userDetails]);
    
    return (
        <Layout title="My Lessons">
            <Seo title="Home" />
            <div className="my-classes">
                <div className="container">
                    <div className="nav-tab">
                        <div className={showPending?"tab-link active":"tab-link"} onClick={()=>setShowPending(true)}><Link style={{textDecoration:'none', color:'revert'}} to="#pending">Pending</Link></div>
                        <div className={!showPending?"tab-link active":"tab-link"} onClick={()=>setShowPending(false)}><Link style={{textDecoration:'none', color:'revert'}} to="#completed">Completed</Link></div>
                    </div>
                    <div id="myPendingClasses" className="animated fadeInLeft" style={showPending?{"display":""}:{display:'none'}}>
                        {bookings.map(k =>{

                            if((new Date(k.end_time).getTime() > Date.now()) && k.isCancelled!==true)
                                return (<Booking booking={k} key={k.id} />)
                            }
                        )}
                    </div>
                    <div id="myCompletedClasses" className="animated fadeInLeft" style={!showPending?{"display":""}:{display:'none'}}>
                        {bookingsR.map(k =>{
                            //console.log(k.isCancelled);
                                if((new Date(k.end_time).getTime() < Date.now()) || k.isCancelled===true){
                                return (<Booking booking={k} key={k.id} />)
                                }
                            }
                        )}
                    </div>
                </div>
            </div>    
        </Layout>
    );
}

export default connect(state => ({ userDetails: state.loginReducer.authenticate.user, bookings: state.userReducer.bookings }), null)(MyClass);
